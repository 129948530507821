import * as React from "react"
import { Link } from "gatsby"

import { Layout } from '../components/layout'
import { Head } from '../components/head'
import { ROUTES } from '../core/constants'

export const NotFoundPage = () => {
  return (
    <Layout>
      <Head title="404" />
      <h1>Page not found</h1>
      <p><Link to={ROUTES.home}>Home</Link></p>
    </Layout>
  )
}
